import apiClient from './api'

export default {
    async get (id) {
        return await apiClient.get(`/cms/users/${id}`)
    },
    async getAll (params = null) {
        return await apiClient.get('/cms/users', { params })
    },
    async paginate (params) {
        params.paginate = true
        params.perPage = params.itemsPerPage
        const result = await apiClient.get('/cms/users', { params })
        result.data.total = Number(result.data.total)
        return result
    },
    async add (params) {
        return await apiClient.post('/cms/users', params)
    },
    async edit (id, params) {
        return await apiClient.put(`/cms/users/${id}`, params)
    },
    async delete (id) {
        return await apiClient.delete(`/cms/users/${id}`)
    },
}